import axios from 'axios';
import {message} from "antd";

let promise = axios.create({
  baseURL: "https://axisocappliance.com/",
  headers: {
    "Content-Type": "application/json",
    accept: "/",
  },
  auth: {
    username: "axis",
    password: "12345678"
  }
})

export const axiosConfig = promise;

axiosConfig.interceptors.response.use(
    async (config) => {
      return config
    },
    error => {
      // message.error(error.response.data.message)

      if(error.response.status == '429'){
        message.error({content: "The form could be submitted only once every 10 minutes. Please try again at a later time", duration: 7, className: "custom-message"})
      } else{
        message.error({content: error.code ?? "Error occurred"})
      }

      return Promise.reject(error)
    }
)



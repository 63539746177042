import Icon from "@ant-design/icons";


const Svg = () => (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="52" height="52" rx="12" fill="#4472E4"/>
        <path d="M31.6963 24.7212C31.7702 24.9059 31.7677 25.1124 31.6894 25.2952C31.6111 25.4781 31.4634 25.6223 31.2787 25.6962L27.1875 27.3325L29.6 30.55C29.7193 30.7091 29.7706 30.9092 29.7425 31.1061C29.7143 31.303 29.6091 31.4807 29.45 31.6C29.2909 31.7193 29.0908 31.7706 28.8939 31.7425C28.697 31.7143 28.5193 31.6091 28.4 31.45L26 28.25L23.6 31.45C23.5409 31.5288 23.4669 31.5952 23.3821 31.6454C23.2974 31.6955 23.2036 31.7285 23.1061 31.7425C23.0086 31.7564 22.9093 31.751 22.8139 31.7265C22.7185 31.7021 22.6288 31.6591 22.55 31.6C22.4712 31.5409 22.4048 31.4669 22.3546 31.3821C22.3045 31.2974 22.2715 31.2036 22.2575 31.1061C22.2436 31.0086 22.249 30.9093 22.2735 30.8139C22.2979 30.7185 22.3409 30.6288 22.4 30.55L24.8125 27.3325L20.7212 25.6962C20.5381 25.6213 20.392 25.4769 20.3148 25.2947C20.2377 25.1125 20.2356 24.9071 20.3092 24.7234C20.3827 24.5397 20.5259 24.3925 20.7075 24.3139C20.8891 24.2353 21.0945 24.2316 21.2788 24.3038L25.25 25.8925V22C25.25 21.8011 25.329 21.6103 25.4697 21.4697C25.6103 21.329 25.8011 21.25 26 21.25C26.1989 21.25 26.3897 21.329 26.5303 21.4697C26.671 21.6103 26.75 21.8011 26.75 22V25.8925L30.7213 24.3038C30.9059 24.2298 31.1124 24.2323 31.2952 24.3106C31.4781 24.3889 31.6223 24.5366 31.6963 24.7212ZM37.75 17V24.3462C37.75 35.3775 28.415 39.0362 26.5438 39.6575C26.1912 39.7775 25.8088 39.7775 25.4563 39.6575C23.585 39.0387 14.25 35.375 14.25 24.3488V17C14.25 16.5359 14.4344 16.0908 14.7626 15.7626C15.0908 15.4344 15.5359 15.25 16 15.25H36C36.4641 15.25 36.9092 15.4344 37.2374 15.7626C37.5656 16.0908 37.75 16.5359 37.75 17ZM36.25 17C36.25 16.9337 36.2237 16.8701 36.1768 16.8232C36.1299 16.7763 36.0663 16.75 36 16.75H16C15.9337 16.75 15.8701 16.7763 15.8232 16.8232C15.7763 16.8701 15.75 16.9337 15.75 17V24.3462C15.75 34.3462 24.23 37.67 25.9288 38.2337C25.9742 38.2513 26.0245 38.2513 26.07 38.2337C27.77 37.67 36.25 34.3475 36.25 24.3462V17Z" fill="white"/>
    </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>

import React from "react";
import {Col, Form, Input, Modal, Row, Select} from "antd";
import InputMask from "antd-mask-input";
import {MyStyledDropdown} from "../MyStyledDropdown/MyStyledDropdown";
import {MyButton} from "../MyButton/MyButton";
import ReCAPTCHA from "react-google-recaptcha";

const {Option} = Select;

export const MyModal = ({screens, form, isModalOpen, handleOk, handleCancel, onFinishForm, servicesList, brandList, recaptchaRef, handleReCaptchaChange, loading}) => {

    return (
        <Modal className="custom-form" title="Book your service" width={!screens.sm ? 400 : !screens.xl ? 728 : 904} open={isModalOpen}
               onOk={handleOk} onCancel={handleCancel} footer={() => {
        }}>
            <Form form={form} onFinish={onFinishForm}>
                <Row justify="space-between">
                    <Col xs={24} xl={12}>
                        <Row justify="space-between">
                            <Col xs={24} lg={{span: 11}}>
                                <Form.Item name="firstName" rules={[{
                                    required: true,
                                    message: "Please enter your first name",
                                }]}>
                                    <Input placeholder="First name" style={{height: 56}}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={{span: 11}}>
                                <Form.Item name="lastName">
                                    <Input placeholder="Last name" style={{height: 56}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} xl={11}>
                        <Form.Item name="streetAddress"
                                   rules={[{required: true, message: "Please enter your street address"}]}>
                            <Input placeholder="Street address" style={{height: 56}}/>
                        </Form.Item>

                    </Col>
                </Row>

                <Row justify="space-between">
                    <Col xs={24} xl={12}>
                        <Form.Item name="phoneNumber" rules={[{
                            required: true,
                            message: "Please enter your phone number",
                            pattern: new RegExp(/^\(\d{3}\)\d{3}-\d{4}$/)
                        }]}>
                            <InputMask mask="(000)000-0000" placeholder="Phone number" maskOptions={{lazy: true}} style={{height: 56}}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={11}>
                        <Form.Item name="zipCode" rules={[{
                            required: true,
                            message: "Please enter your zip code",
                            pattern: new RegExp(/^\d{5}$/)
                        }]}>
                            <InputMask mask="00000" maskOptions={{placeholderChar: "x", lazy: true}} placeholder="Zip code"
                                       style={{height: 56}}/>
                        </Form.Item>

                    </Col>
                </Row>

                <Row justify="space-between">
                    <Col xs={24} xl={12}>
                        <Form.Item name="email" rules={[{
                            required: true,
                            message: "Please enter your email address in a correct format",
                            pattern: new RegExp(/^[a-zA-Z0-9^<>()[\]\\_%.#\s@"$&!]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/gm)
                        }]}>
                            <Input placeholder="Email" style={{height: 56}}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={11}>
                        <Form.Item name="requestedDayTime"
                                   rules={[{required: true, message: "Please enter your requested day and time"}]}>
                            <Input placeholder="Requested day and time" style={{height: 56}}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="space-between">
                    <Col xs={24} xl={12}>
                        <Form.Item name="applianceType" style={{height: 56}}
                                   rules={[{required: true, message: "Please enter your application type"}]}>
                            <Select showSearch virtual={false}
                                    dropdownRender={(menu) => <MyStyledDropdown>{menu}</MyStyledDropdown>}
                                    filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Appliance type" style={{height: 56}}>
                                {servicesList.map((item, index) => (
                                    <Option key={`service-main-${index}`} value={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={11}>
                        <Form.Item name="applianceBrand"
                                   rules={[{required: true, message: "Please enter your appliance brand"}]}>
                            <Select showSearch virtual={false}
                                    dropdownRender={(menu) => <MyStyledDropdown>{menu}</MyStyledDropdown>}
                                    filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    placeholder="Appliance brand" style={{height: 56}}>
                                {brandList.map((item, index) => (
                                    <Option value={item} key={item + "--" + index}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="space-between">
                    <Col xs={24} xl={12}>
                        <Form.Item name="model">
                            <Input placeholder="Model (if available)" style={{height: 56}}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={11}>
                        <Form.Item name="serial">
                            <Input placeholder="Serial (if available)" style={{height: 56}}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col xs={24} lg={24}>
                        <Form.Item name="description" rules={[{required: true, message: "Please enter your message"}]}>
                            <Input.TextArea autoSize={{minRows: 3}} placeholder="Description of the problem"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Col style={{marginBottom: 16}}>
                    <ReCAPTCHA ref={recaptchaRef} size={"normal"} sitekey="6Lec500qAAAAANOu-acANOYHu319cob-pPdVb_Ps"
                               onChange={handleReCaptchaChange}/>
                </Col>

                <Row justify="center">
                    <MyButton loading={loading} type="primary" htmlType="submit">Submit</MyButton>
                </Row>
            </Form>
        </Modal>
    )
}
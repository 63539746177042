export const LightTheme = {
    token: {
        colorPrimary: "#4472E4",
        colorInfo: "#4472E4",
        secondaryColor: "#E46644",
        fontFamily: "Inter"
    },
    components: {
        Select: {
            hoverBorderColor: "#E46644",
            activeBorderColor: "#E46644",
        },
    },
    colorText: "#1D1D1F"
}
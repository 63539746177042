import React from "react";
import {StyledFooter} from "./Footer.styles";
import {Col, Divider, Row, Grid, Flex} from "antd";
import {FooterLogo, MailIcon, PhoneIcon} from "../../assets";
import dayjs from "dayjs";
import {Link, useNavigate} from "react-router-dom";

const {useBreakpoint} = Grid

export const Footer = () => {
    const screens = useBreakpoint();
    const navigate = useNavigate();

    return (
        <StyledFooter>
            <Col xs={{
                pull: 1,
                span: 22,
                push: 1,
            }} lg={{
                pull: 3,
                span: 18,
                push: 3,
            }} xl={{
                pull: 4,
                span: 16,
                push: 4,
            }}>
                <Row className="adaptive-container" style={{flexDirection: "column"}}>
                    <FooterLogo className="footerLogo"/>
                    <Flex wrap={"wrap"}>

                        <Row className="mailContainer" align="middle">
                            <MailIcon/>
                            <span>service@axisocs.com</span>
                        </Row>
                        <Row className="phoneContainer" align="middle">
                            <PhoneIcon/>
                            <span>(949) 216-7788</span>
                        </Row>
                    </Flex>
                </Row>
                <Row className="normal-container" justify={{xs: "center", sm: "space-between"}}>
                    <FooterLogo className="footerLogo"/>
                    <Link to="mailto:service@axisocs.com">
                        <Row className="mailContainer" align="middle">
                            <MailIcon/>
                            <span>service@axisocs.com</span>
                        </Row>
                    </Link>
                    <Link to="tel:(949) 216-7788">
                        <Row className="phoneContainer" align="middle">
                            <PhoneIcon/>
                            <span>(949) 216-7788</span>
                        </Row>
                    </Link>
                </Row>
            </Col>
            <Divider style={{borderColor: "rgba(255, 255, 255, 0.15)"}}/>
            <Col xs={{
                pull: 1,
                span: 22,
                push: 1,
            }} lg={{
                pull: 3,
                span: 18,
                push: 3,
            }} xl={{
                pull: 4,
                span: 16,
                push: 4,
            }}>

                {!screens.md ? (
                    <Row align="middle" style={{flexDirection: "column"}}>
                        <Col>
                            <Row className="documents">
                                <span onClick={() => navigate("/privacy")}>Privacy Policy</span>
                                <span onClick={() => navigate("/terms")}>Terms and Conditions</span>
                            </Row>
                        </Col>
                        <Col>
                            <span
                                className="copyright">Axis OC Service © {dayjs().year()} All rights reserved.</span>
                        </Col>
                    </Row>
                ) : (
                    <Row justify="space-between">
                        <Col>
                            <Row className="documents">
                                <span onClick={() => navigate("/privacy")}>Privacy Policy</span>
                                <span style={{marginLeft: 20}} onClick={() => navigate("/terms")}>Terms and Conditions</span>
                            </Row>
                        </Col>
                        <Col>
                            <span
                                className="copyright">Axis OC Service © {dayjs().year()} All rights reserved.</span>
                        </Col>
                    </Row>
                )}

            </Col>
        </StyledFooter>
    )
}
import React from "react";
import {StyledCarousel, StyledSection9Container} from "./Section9.styles";
import {Col, Row, Grid, Image} from "antd";

import WalterImage from '../../../../assets/images/section9/WalterImage.png'
import DefaultImage from '../../../../assets/images/section9/DefaultImage.png'

const {useBreakpoint} = Grid;

export const Section9 = () => {

  const screens = useBreakpoint()

    const reviews = [
        {
            icon: DefaultImage,
            userName: "Sofia E.",
            comment: "I recently had some problems with my subzero refrigerator, my home warranty referred me to Axis OC Services. I was extremely pleased with Timur the technician. He was very competent, knowledgeable and very professional. He diagnosed my refrigerator problem and was able to fix it without any issues. \n I highly recommend him to others. Thank you Timur for your precision and great customer service."
        },
        {
            icon: DefaultImage,
            userName: "Yolanda R.",
            comment: "When your refrigerator is not cooling you want service asap from an honest, knowledgeable technician along with their company that keeps their word. Axis OC Service was that and more. From the first call to work completion the office scheduler and technician were so helpful that I was calm. They expedited the part, created a temporary fix that allowed no food lost until the part arrived. Additionally, the technician showed up at the scheduled times. Thanks Axis"
        },
        {
            icon: WalterImage,
            userName: "Wolter M.",
            comment: "I am a property manager in Costa Mesa. I had a tenant whose microwave went out. I called these people and they had a tech out the next day. The tenant was not home when they arrived but left a key under the mat. They took care of everything for a fair price. The owner and tenant could not be happier. I recommend this servic."
        },
        {
            icon: DefaultImage,
            userName: "Mustafa N.",
            comment: "Artem quickly diagnosed the problem with my Kenmore fridge and ordered the part. When it arrived, he quickly installed and explained everything to me. Artem was pleasant, efficient and solved my problem. Job well done!"
        },
    ]



  const settings = {
      slidesToShow: !screens.md ? 1 : 2,
      slidesToScroll: !screens.md ? 1 : 2,
  }

    return (
        <StyledSection9Container>
            <Col xs= {{
                pull: 1,
                span: 22,
                push: 1,
            }}lg={{
                pull: 3,
                span: 18,
                push: 3,
            }} xl={{
                pull: 4,
                span: 16,
                push: 4,
            }}>
                <Row className="textBlock" style={{flexDirection: "column", marginTop: 50, marginBottom: 30}} align="middle">
                    <h1 className="title">Testimonials</h1>
                    <span className="subtitle">Read reviews about our repair, installation and maintenance services. Our trained experts are {!screens.lg ? null : <br />}  always ready to help! ♥</span>
                </Row>
              <StyledCarousel {...settings} dots={true} >
                {
                  reviews.map((reviewItem, index) => (
                    <div key={index + "-user-review"} className="item">
                      <span className="item_text">{reviewItem.comment}</span>

                      <Row align="middle" className="userInfo">
                        <Image src={reviewItem.icon} preview={false}/>
                        <span>{reviewItem.userName}</span>
                      </Row>
                    </div>
                  ))
                }
              </StyledCarousel>
            </Col>
        </StyledSection9Container>
    )
}